<template>
  <div>
    <outgoing-invoices-home :outgoingInvoices="outgoingInvoices"/>

  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'

import OutgoingInvoicesHome from '@/components/home/outgoingInvoice/OutgoingInvoice'
import useAPI from '@/utils/useAPI'

export default {
  components: {
    OutgoingInvoicesHome
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { outgoingInvoices } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components

      // Data

      // Computed
      outgoingInvoices,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () { },
  created () {
  }
}
</script>

<style lang="scss">

</style>